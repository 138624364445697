var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "password_strength_container" },
    [
      _c("div", { staticClass: "password_strength_bar" }, [
        _c("div", {
          staticClass: "password_strength_fill",
          attrs: {
            "data-score":
              _vm.$props.password_input != null &&
              _vm.$props.password_input != ""
                ? _vm.password_strength
                : "",
          },
        }),
      ]),
      _vm.$props.password_input != null &&
      _vm.$props.password_input != "" &&
      _vm.password_strength >= 0 &&
      _vm.password_strength < 3
        ? [_c("div", { staticClass: "weak_password" }, [_vm._v("Too weak")])]
        : _vm.$props.password_input != null &&
          _vm.$props.password_input != "" &&
          _vm.password_strength === 3
        ? [
            _c("div", { staticClass: "moderate_password" }, [
              _vm._v("Moderate password"),
            ]),
          ]
        : _vm.$props.password_input != null &&
          _vm.$props.password_input != "" &&
          _vm.password_strength === 4
        ? [
            _c("div", { staticClass: "strong_password" }, [
              _vm._v("Strong password!"),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }