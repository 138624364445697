var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: "top_layer",
      staticClass: "loading_screen_overlay_main_loader",
      style: _vm.isFirst === true ? "background: #262626 !important;" : "",
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading_screen_branding_container" }, [
      _c("img", {
        staticClass: "loading_screen_branding",
        attrs: {
          alt: "loading brand image",
          src: "/images/branding/cdt_green.png",
        },
      }),
      _c("div", { staticClass: "circle_container" }, [
        _c("div", { staticClass: "circle_loader" }, [
          _c("div", { staticClass: "circle_dot_loader" }),
          _c("div", { staticClass: "circle_dot_loader" }),
          _c("div", { staticClass: "circle_dot_loader" }),
          _c("div", { staticClass: "circle_dot_loader" }),
          _c("div", { staticClass: "circle_dot_loader" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }