import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import userAPI from "../api/userAPI"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        windowWidth: window.innerWidth,
        hasLoaded: 0,
        home_page: "/",
        current_page_name: '',
        hasRota: 0,
        forename: null,
        surname: null,
        gender_id: null,
        theme_id: 1,
        theme_original_id: null,
        unlocked_themes: [1, 5, 6, 7, 13],
        unlocked_themes_default: [1, 5, 6, 7, 13],
        show_animations: true,
        show_vanta: false,
        event_theme_switcher: true,
        sidebar_head_item: null,
        sidebar_content_item: null,
        level_detail: null,
        level_percentage: 0,
        exp_animation: false,
        exp_orb_shadow: false,
        colour_blind_mode: '',

        allow_sounds: true,
        notification_sound: '/sound/Default/notification.mp3',
        error_sound: '/sound/Default/error.mp3',

        profile_flair_animation: null,
        profile_image: null,
        profile_background: null,
        profile_flair: null,
        profile_flair_position: null,
        profile_flair_normal_settings: null,
        profile_flair_settings_settings: null,
        userProfileBackgroundFill: null,

        profile_flair_animation_id: null,
        profile_image_id: null,
        profile_background_id: null,
        profile_flair_id: null,
        userProfileBackgroundFill_id: null,

        user_permissions: {},
        service_access: [],
        sidebar_reduced: false,
        search_sidebar_reduced: true,

        address_loading: false,

        //SOCKET IO
        isConnected: 1,
        isIdle: 0,
        connection_internval_set: 0,
        latency: 'Connection - 0ms',
        latency_text: ' - ',
        pulse_stage: 'pulse-green',
        connection_text: 'Waiting...',
        socket_start_time: null,
        isRoutePlanningEditing: 0,
        isRoutePlanningEditingOtherUser: 0,

        //SETTINGS
        sidebar_expanded: true,
        record_tabs_vertical: true,
        client_record_data_default_view: true,

        /*Pages
            Available:
                slide-fade (Default)
                normal-fade
                page-bounce
                page-zoom
                page-slide
                none-animation
        */

        page_transition: 'slide-fade',
        
        /*Table TOP Sidebars
            Available:
                slide-fade (Default)
                roll-down
                none-animation
        */

        side_bar_top_animation: 'roll-down',

        /*Table LEFT Sidebars
            Available:
                roll-side (Default)
                none-animation
        */

        side_bar_left_animation: 'roll-side',

        /*Forms
            Available:
                bounce (Default)
                slide-fade
                normal-fade
                page-bounce
                page-zoom
                page-slide
                none-animation
        */

        form_transition: 'bounce',

        /*Record Tabs
            Available:
                slide-fade (Default)
                normal-fade
                page-bounce
                page-zoom
                page-slide
                none-animation
        */

        record_tab_transition: 'slide-fade',

        data_tables_filters_at_the_top: false,
        data_tables_automatic_updates: true,
        data_tables_open_record_in_new_window: false,
        data_tables_reactive_filters: false,
        data_tables_hide_empty_filters: false,
        data_tables_expand_all_filters: false,
        data_tables_filters_hidden: false,
        data_tables_sticky_columns: false,
        data_tables_total_rows: 25,

        font_size: 3
    },
    mutations: {
        setWindowWidth(state) {
            state.windowWidth = window.innerWidth;
        },
        add_exp(state, payload){
            if(state.exp_animation === false) {
                state.exp_animation = true;
                setTimeout(function(){
                    state.exp_orb_shadow = true;
                    setTimeout(function(){
                        state.exp_orb_shadow = false;
                    }, 6000);
                }, 1000);
                setTimeout(function(){
                    state.exp_animation = false;
                }, 7000);
            }
            var exp = payload.exp;
            var direction = payload.direction;
            var this_ = payload.this;
            if(direction === 'up') {
                state.level_detail.user_experience = parseInt(state.level_detail.user_experience) + exp;
                if(parseInt(state.level_detail.user_experience) > parseInt(state.level_detail.experience_end)) {
                    if(parseInt(state.level_detail.level_name) === parseInt(100)) {
                        state.level_detail.user_experience = parseInt(state.level_detail.experience_end);
                    } else {
                        if(parseInt(state.level_detail.level_name) === parseInt(1)) {
                            state.level_detail.experience_start = parseInt(state.level_detail.experience_start) + parseInt(1001);
                        } else {
                            state.level_detail.experience_start = parseInt(state.level_detail.experience_start) + parseInt(1000);
                        }
                        state.level_detail.experience_end = parseInt(state.level_detail.experience_end) + parseInt(1000);
                        state.level_detail.level_name = parseInt(state.level_detail.level_name) + parseInt(1);
                    }
                }
            } else {
                if(parseInt(state.level_detail.user_experience) > exp) {
                    state.level_detail.user_experience = parseInt(state.level_detail.user_experience) - exp;
                } else {
                    state.level_detail.user_experience = 0;
                }
                if(parseInt(state.level_detail.user_experience) < parseInt(state.level_detail.experience_start)) {
                    if(parseInt(state.level_detail.level_name) === parseInt(1)) {
                        state.level_detail.experience_start = parseInt(0);
                        state.level_detail.user_experience = parseInt(state.level_detail.experience_start);
                    } else {
                        if(parseInt(state.level_detail.level_name) === parseInt(2)) {
                            state.level_detail.experience_start = parseInt(state.level_detail.experience_start) - parseInt(1001);
                        } else {
                            state.level_detail.experience_start = parseInt(state.level_detail.experience_start) - parseInt(1000);
                        }
                        state.level_detail.experience_end = parseInt(state.level_detail.experience_end) - parseInt(1000);
                        state.level_detail.level_name = parseInt(state.level_detail.level_name) - parseInt(1);
                    }
                }
            }
            setTimeout(function(){
                state.level_percentage = this_.$data_render.user_level_progress(state.level_detail);
            }, 3000);
        },
        SOCKET_CONNECT(state) {
            var this_ = this;
            state.isConnected = 1;
            this_._vm.$socket.client.volatile.emit('change_page', router.history.current.fullPath);
            if(state.connection_internval_set === 0) {
                state.connection_internval_set = 1;
                setInterval(function() {
                    state.socket_start_time = Date.now();
                    this_._vm.$socket.client.volatile.emit('sent_latency');
                }, 5000);
            }
        },
        SOCKET_DISCONNECT(state) {
            state.isConnected = 0;
            state.pulse_stage = 'disconnected';
            state.connection_text = 'Disconnected';
            state.latency = 'Disconnected';
            state.latency_text = ' - ';
        },
        SOCKET_NO_SESSION(state) {
            state.isConnected = 0;
            userAPI.logout().then(function(return_data){router.replace({name: 'Login'});});
        },
        SOCKET_FIRST_LOGIN(state) {
            router.replace({name: 'FirstLogin', query: {redirect: router.path}});
        },
        SOCKET_MULTIPLE_DEVICE(state) {
            router.replace({name: 'Multiple'});
        },
        SOCKET_RECEIVED_LATENCY(state) {
            var latency_ms = Date.now() - state.socket_start_time;

            if (latency_ms >= 500) {
                state.pulse_stage = 'pulse-red';
                state.connection_text = 'Weak Connection';
            } else if (latency_ms >= 150 && latency_ms < 500){
                state.pulse_stage = 'pulse-amber';
                state.connection_text = 'Slow Connection';
            } else {
                state.pulse_stage = 'pulse-green';
                state.connection_text = 'Good Connection';
            }

            state.latency = 'Connection - ' + latency_ms + 'ms';
            state.latency_text = latency_ms + 'ms';
            state.isConnected = 1;

            latency_ms = null;
        },
        SOCKET_SOCKET_REFRESH_ALL_PAGES_CONFIRMED(state) {
            router.go();
        },
        /*SOCKET_ROUTE_LOCKED(state) {
            console.log("route_locked");
            state.isRoutePlanningEditingOtherUser = 1;
        },
        SOCKET_ROUTE_UNLOCKED(state) {
            console.log("route_unlocked");
            state.isRoutePlanningEditingOtherUser = 0;
        }*/
    },
    actions: {

    },
    modules: {
    
    }
})

/*add_exp(){
    this.$store.commit('add_exp', {exp: 250, direction: 'up', this: this});
},
reduce_exp(){
    this.$store.commit('add_exp', {exp: 250, direction: 'down', this: this});
}*/
