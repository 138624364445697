var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.color,
        timeout: _vm.timer,
        top: "",
        center: "",
        outlined: "",
      },
      model: {
        value: _vm.showSnackbar,
        callback: function ($$v) {
          _vm.showSnackbar = $$v
        },
        expression: "showSnackbar",
      },
    },
    [
      _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(_vm.icon))]),
      _vm._v(_vm._s(_vm.message) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }