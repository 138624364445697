var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: tooltip }) {
            return [
              !_vm.isPatientRecord
                ? [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          attrs: {
                            elevation: "2",
                            tile: "",
                            icon: "",
                            "aria-label": "View Record",
                          },
                          on: { click: _vm.view_record_app },
                        },
                        { ...tooltip }
                      ),
                      [_c("v-icon", [_vm._v("fas fa-eye")])],
                      1
                    ),
                  ]
                : _vm.isPatientRecord
                ? [
                    _vm.isPatientRecord === 0
                      ? [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  elevation: "2",
                                  tile: "",
                                  icon: "",
                                  "aria-label": "View Record",
                                },
                                on: { click: _vm.view_record_app },
                              },
                              { ...tooltip }
                            ),
                            [_c("v-icon", [_vm._v("fas fa-eye")])],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v("View Record")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }