var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "transition",
        { attrs: { name: "fade_loader" } },
        [_vm.show === false ? _c("fs-loader") : _vm._e()],
        1
      ),
      _c("error-notifications"),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }